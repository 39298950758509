import BlogArticleCard, {
  BlogArticleCardContent,
  BlogArticleCardImageContainer,
  BlogArticleCardTitleText,
} from '@/components/blog/BlogArticleCard';
import ExpandingBlock from '@/components/ExpandingBlock';
import GroupedInput from '@/components/forms/GroupedInput';
import Image from '@/components/Image';
import { Container, MAX_CONTAINER_WIDTH } from '@/components/layout/Container';
import Link from '@/components/Link';
import { BLOG_NAV_HEIGHT } from '@/components/navigation/BlogNavigation';
import { HubspotFieldNames } from '@/constants/hubspot';
import { FRONT_PAGE_SEARCH } from '@/constants/routes';
import { GlobalContext } from '@/context';
import { UserContext } from '@/context/user';
import { BlogHomepageQuery } from '@/graphql';
import BlogHomepageQueryDocument from '@/graphql/queries/BlogHomepage.gql';
import gtm from '@/lib/gtm';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { getStaticPageProps } from '@/lib/next';
import { maybeNotSet, notEmpty } from '@/lib/typescript-helpers';
import { ConditionalWrapper } from '@/lib/utils';
import { COLORS } from '@/styles/color';
import { hexToRGBA } from '@/styles/color/utils';
import {
  applyBreakpointPadding,
  mediaBreakpointDown,
  mediaBreakpointUp,
  paddingX,
  paddingY,
} from '@/styles/layout/utils';
import { FONT_FAMILIES, FONT_WEIGHTS, TEXT_SIZES } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import BlogTemplate from '@/templates/BlogTemplate';
import { cloneDeep } from 'lodash';
import React, { useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';

const Hero = styled.header`
  padding-top: ${remCalc(BLOG_NAV_HEIGHT)};
  background: linear-gradient(192.98deg, ${COLORS.ORANGE.HIGHLIGHT} 36.06%, ${COLORS.WHITE} 115.95%);
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
`;

const HeroContainer = styled(Container)`
  ${mediaBreakpointUp('desktopWide')} {
    margin-bottom: ${remCalc(60)};
  }
`;

const HeroImageRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: ${remCalc(25)};
  ${paddingX(15)}
  column-gap: ${remCalc(20)};
  max-width: ${remCalc(1440)};
  pointer-events: none;

  ${mediaBreakpointUp('desktopWide')} {
    ${paddingX(0)}
    position: absolute;
    bottom: 0;
  }
`;

const HeroImageWrapper = styled.div`
  position: relative;
  margin-top: auto;
  img {
    width: 100%;
  }
`;

const HeroHeadline = styled.h1`
  ${customTextSize(32, 33.6)};
  letter-spacing: -0.01em;
  text-align: center;
  font-family: ${FONT_FAMILIES.SERIF};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  max-width: ${remCalc(430)};

  ${mediaBreakpointUp('desktop')} {
    ${customTextSize(54, 60)};
  }
`;

const HeroGroupedInput = styled(GroupedInput)`
  --grouped-input-border-color: ${COLORS.BLACK};
  --grouped-input-button-hover-color: ${COLORS.ORANGE.BASE};

  margin-top: ${remCalc(32)};
  pointer-events: auto;

  ${mediaBreakpointUp('tablet')} {
    max-width: ${remCalc(500)};
  }
`;

const BORDER_COLOR = hexToRGBA(COLORS.GRAY.BASE, 0.5);

const FeaturedSection = styled.section`
  padding: ${remCalc(40, 0)};
`;

const FeaturedCategoryList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: ${remCalc(8)};

  ${mediaBreakpointUp('desktop')} {
    flex-direction: row;
    row-gap: ${remCalc(10)};
    column-gap: ${remCalc(10)};
    flex-wrap: wrap;
  }
`;

const FeaturedCategoryItem = styled.li`
  ${TEXT_SIZES[18]};
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  border-bottom: 2px solid ${COLORS.BLACK};

  ${mediaBreakpointUp('desktop')} {
    background-color: ${COLORS.GRAY.HIGHLIGHT};
    border-bottom: none;
    flex: 1 1 auto;
  }
`;

const FeaturedCategoryLink = styled.a`
  display: inline-block;
  padding-bottom: ${remCalc(5)};

  ${mediaBreakpointUp('desktop')} {
    display: block;
    padding: 15px 20px;
    text-align: center;
  }
`;

const RecentArticlesSection = styled.section`
  ${paddingY(40)};
`;

const RecentArticlesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${remCalc(24)};

  ${mediaBreakpointUp('tablet')} {
    margin-bottom: ${remCalc(40)};
  }
`;

const RecentArticlesHeadline = styled.h3`
  display: inline-block;
  ${TEXT_SIZES[20]};
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  color: ${COLORS.BLACK};

  ${mediaBreakpointUp('tablet')} {
    ${customTextSize(36, 40)};
  }
`;

const RecentArticlesGrid = styled.div`
  display: grid;
  gap: ${remCalc(30)};

  ${mediaBreakpointUp('tablet')} {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    & > :first-child {
      grid-column: 1 / -1;
    }
  }

  ${mediaBreakpointUp('desktopWide')} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const CategorySection = styled.section`
  ${paddingY(40)}

  ${mediaBreakpointUp('desktop')} {
    ${applyBreakpointPadding};
    max-width: ${remCalc(MAX_CONTAINER_WIDTH)};
    box-sizing: content-box;

    margin: 0 auto;
    ${paddingY(80)}
  }
`;

const ListGrid = styled.div`
  ${applyBreakpointPadding};

  display: grid;
  grid-template-columns: 100%;
  gap: ${remCalc(80)};

  ${mediaBreakpointUp('desktop')} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    ${paddingX(0)}
  }
`;

const ListColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListHeadline = styled.p`
  ${customTextSize(23, 29)}
  font-weight: ${FONT_WEIGHTS.BOLD};
  font-family: ${FONT_FAMILIES.SERIF};
  margin-bottom: ${remCalc(20)};

  ${mediaBreakpointUp('desktop')} {
    ${customTextSize(32, 40)}
    font-weight: ${FONT_WEIGHTS.NORMAL};
  }
`;

const List = styled.ul`
  display: grid;
  grid-auto-rows: 1fr;

  border-top: 1px solid ${COLORS.BLACK};

  ${mediaBreakpointUp('desktop')} {
    flex-grow: 1;
  }
`;

interface ListItemProps {
  isFiller?: true;
}

const ListItem = styled.li<ListItemProps>`
  display: flex;
  align-items: center;

  ${(props) =>
    props.isFiller &&
    css`
      ${mediaBreakpointDown('tablet')} {
        display: none;
      }
    `}

  ${(props) =>
    !props.isFiller &&
    css`
      border-bottom: 1px solid ${BORDER_COLOR};
    `}

  ${mediaBreakpointUp('desktop')} {
    ${paddingY(24)}
  }
`;

const ListItemLink = styled.a`
  ${customTextSize(15, 19)}
  ${paddingY(24)}
  display: inline-block;
  width: 100%;

  ${mediaBreakpointUp('desktop')} {
    ${customTextSize(19, 24)}
    ${paddingY(0)}

    display: inline;
    width: auto;
  }
`;

const SeeAllLink = styled.a`
  ${TEXT_SIZES[13]};
  display: inline-block;
  color: ${COLORS.BLACK};
  font-weight: ${FONT_WEIGHTS.BOLD};
  border-bottom: 2px solid;
  padding-bottom: ${remCalc(2)};

  ${mediaBreakpointUp('tablet')} {
    ${TEXT_SIZES[16]};
  }
`;

const SubscribeButtonWrapper = styled.div`
  margin-top: ${remCalc(50)};
  text-align: center;
`;

const FeaturedBlogArticleCard = styled(BlogArticleCard)`
  ${mediaBreakpointUp('desktop')} {
    & {
      flex-direction: row;
    }

    ${BlogArticleCardImageContainer} {
      border-radius: ${remCalc(10, 0, 0, 10)};
      width: 50%;
    }

    ${BlogArticleCardContent} {
      padding-right: ${remCalc(60)};
    }

    ${BlogArticleCardTitleText} {
      ${customTextSize(32, 38)};
    }
  }

  ${mediaBreakpointUp('desktopWide')} {
    ${BlogArticleCardImageContainer} {
      border-radius: ${remCalc(10, 0, 0, 10)};
      width: 66%;
    }
  }
`;

// #endregion - Components

interface PageProps extends BlogHomepageQuery {}

const Index: PageTemplate<PageProps> = (props) => {
  const { entry, recentArticles, guestArticles, frontNews } = props;
  const { toggleSubscribeModal } = useContext(GlobalContext);
  const { userData, setUserData } = useContext(UserContext);
  const [currentBreakpoint, activeBreakpoints] = useBreakpoint();

  const articles = useMemo(() => {
    const arr = (cloneDeep(recentArticles) ?? []).filter(notEmpty);

    const featuredArticle = entry?.featuredArticles?.[0];

    if (featuredArticle) {
      const index = arr?.findIndex((article) => article.id === featuredArticle.id);

      if (typeof index === 'number') {
        arr?.splice(index, 1);
      }

      arr.unshift(featuredArticle);
    }

    return arr?.filter(notEmpty).slice(0, 13);
  }, [recentArticles, entry?.featuredArticles]);

  return (
    <>
      <Hero>
        <HeroContainer>
          <HeroHeadline>{entry?.heroTitle}</HeroHeadline>
          <HeroGroupedInput
            name="email"
            type="email"
            value={userData[HubspotFieldNames.Email]}
            placeholder="Your Email Address"
            submitText="Subscribe"
            split={currentBreakpoint === 'mobile'}
            onValueChange={(email) => setUserData({ [HubspotFieldNames.Email]: email })}
            onSubmit={() => {
              toggleSubscribeModal(true);
            }}
          />
        </HeroContainer>

        <HeroImageRow>
          <HeroImageWrapper>
            <Image
              src={'/assets/blog/homepage/front-page-hero-left-v2-resized.png'}
              width={662}
              height={475}
              alt={
                'Illustration of two people sitting in chairs. One is appears to be taking notes and the other appears to be talking.'
              }
              sizes="(max-width: 767px) 100vw, 398px"
            />
          </HeroImageWrapper>
          <HeroImageWrapper>
            <Image
              src={'/assets/blog/homepage/front-page-hero-right.png'}
              width={662}
              height={475}
              alt={'Illustration of two people sitting on a sofa looking at a laptop computer.'}
              sizes="(max-width: 767px) 100vw, 378px"
            />
          </HeroImageWrapper>
        </HeroImageRow>
      </Hero>

      <FeaturedSection>
        <Container>
          <div>
            <ConditionalWrapper
              condition={!activeBreakpoints.includes('desktop')}
              wrapper={(children) => (
                <ExpandingBlock
                  title="Sections"
                  onToggle={(isOpen) => {
                    gtm.track(isOpen ? 'expanded_menu' : 'collapsed_menu', {
                      location: 'Featured Categories',
                      element_label: 'Sections',
                    });
                  }}
                >
                  {children}
                </ExpandingBlock>
              )}
            >
              <FeaturedCategoryList>
                {entry?.blogCategories?.map((category) => {
                  const onClick = () => {
                    gtm.track('click', {
                      location: 'Featured Categories',
                      category_title: maybeNotSet(category?.title),
                    });
                  };

                  return (
                    <FeaturedCategoryItem key={category?.slug}>
                      <Link href={category?.url ?? '#'}>
                        <FeaturedCategoryLink onClick={onClick} title={category?.title!}>
                          {category?.title}
                        </FeaturedCategoryLink>
                      </Link>
                    </FeaturedCategoryItem>
                  );
                })}
              </FeaturedCategoryList>
            </ConditionalWrapper>
          </div>
        </Container>
      </FeaturedSection>

      <RecentArticlesSection>
        <Container>
          <RecentArticlesHeader>
            <RecentArticlesHeadline>Articles</RecentArticlesHeadline>
            <Link href={FRONT_PAGE_SEARCH}>
              <SeeAllLink>See all ›</SeeAllLink>
            </Link>
          </RecentArticlesHeader>

          <RecentArticlesGrid>
            {articles?.map((article, index) => {
              const Card = index === 0 ? FeaturedBlogArticleCard : BlogArticleCard;
              const image = article.coverImage?.[0];

              return (
                <Card
                  key={article.id}
                  title={article.title!}
                  href={article.url!}
                  category={article.blogCategories?.[0]?.title!}
                  summary={article.summary!}
                  image={image && <Image src={image.url!} alt={image.altText!} fill />}
                  imageObjectPosition={
                    image && image.focalPoint ? image.focalPoint.map((pos) => `${pos! * 100}%`).join(' ') : 'center'
                  }
                  onClick={() => {
                    gtm.track('click', {
                      element_type: 'link',
                      element_label: `Article | Position ${index + 1} | ${article.title}`,
                      location: 'Recent Articles',
                    });
                  }}
                />
              );
            })}
          </RecentArticlesGrid>

          <SubscribeButtonWrapper>
            <Link href={FRONT_PAGE_SEARCH}>
              <SeeAllLink>See all ›</SeeAllLink>
            </Link>
          </SubscribeButtonWrapper>
        </Container>
      </RecentArticlesSection>

      <CategorySection>
        <ListGrid>
          <ListColumn>
            <ListHeadline>Front News</ListHeadline>
            <List>
              {frontNews
                ?.map((article) => (
                  <ListItem key={article?.id}>
                    <Link href={article?.url ?? '#'}>
                      <ListItemLink
                        onClick={() => {
                          gtm.track('click', {
                            element_type: 'link',
                            location: `News Article List`,
                            element_label: maybeNotSet(article?.title),
                          });
                        }}
                      >
                        {article?.title}
                      </ListItemLink>
                    </Link>
                  </ListItem>
                ))
                .concat(Array(5).fill(<ListItem isFiller />))
                .slice(0, 5)}
            </List>
          </ListColumn>

          <ListColumn>
            <ListHeadline>Popular</ListHeadline>
            <List>
              {entry?.popularArticles
                ?.map((article) => (
                  <ListItem key={article?.id}>
                    <Link href={article?.url ?? '#'}>
                      <ListItemLink
                        onClick={() => {
                          gtm.track('click', {
                            element_type: 'link',
                            element_label: maybeNotSet(article?.title),
                            location: 'Popular Article List',
                          });
                        }}
                      >
                        {article?.title}
                      </ListItemLink>
                    </Link>
                  </ListItem>
                ))
                .concat(Array(5).fill(<ListItem isFiller />))
                .slice(0, 5)}
            </List>
          </ListColumn>

          <ListColumn>
            <ListHeadline>Guest Writers</ListHeadline>
            <List>
              {guestArticles
                ?.map((article) => (
                  <ListItem key={article?.id}>
                    <Link href={article?.url ?? '#'}>
                      <ListItemLink
                        onClick={() => {
                          gtm.track('click', {
                            element_type: 'link',
                            element_label: maybeNotSet(article?.title),
                            location: 'Guest Writers Article List',
                          });
                        }}
                      >
                        {article?.title}
                      </ListItemLink>
                    </Link>
                  </ListItem>
                ))
                .concat(Array(5).fill(<ListItem isFiller />))
                .slice(0, 5)}
            </List>
          </ListColumn>
        </ListGrid>
      </CategorySection>
    </>
  );
};

Index.template = BlogTemplate;

export default Index;

export const getStaticProps = getStaticPageProps<PageProps>('blog', async ({ client }) => {
  const data = await client.request<BlogHomepageQuery>(BlogHomepageQueryDocument);

  return {
    props: {
      ...data,
    },
  };
});
